<template>
    <div/>
</template>

<script>
export default {
    name: 'Show',
};
</script>

<style lang="scss">
</style>
